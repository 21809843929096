import { motion } from "framer-motion";
import { useRef, useEffect, useState, React } from "react";
import styleImages from "./styleImages";
import Service from "./services";
import ServiceMobile from "./servicesMobile";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import SimpleSlider from "./homeSlider";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { EffectCoverflow, Pagination, Autoplay } from "swiper";

const SalonMobile = require("../assets/SalonMobile.jpg");

const Helen = require("../assets/Helen.png");
const Mekdes = require("../assets/mekdi.JPEG");
const Instagram = require("../assets/instagram.png");
const quote = require("../assets/quote.png");
const user1 = require("../assets/RD.png");
const user2 = require("../assets/HM.png");
const user3 = require("../assets/TM.png");
const user4 = require("../assets/GA.png");
const sectionb = require("../assets/section-break.png");

const Home = () => {
  const [width, setWidth] = useState(0);
  const carousel = useRef();
  const ref = useRef(null);
  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  }, []);

  return (
    <div className="Home" id="Home">
      <div className="Desktop">
        <SimpleSlider />
      </div>
      <div>
        <img className="SalonMobile" src={SalonMobile} alt="Salon" />
      </div>

      <div className="childh">
        <motion.div
          ref={carousel}
          className="carousel"
          whileTap={{ cursor: "grabbing" }}
        >
          <motion.div
            drag="x"
            dragConstraints={{ right: 0, left: -width }}
            dragTransition={{ bounceStiffness: 500, bounceDamping: 10 }}
            className="inner-carousel"
          >
            {styleImages.map((styleImage, index) => {
              return (
                <motion.div
                  className="item"
                  key={styleImage}
                  whileHover={{ scale: 1.2 }}
                >
                  <img src={styleImage} alt="Hair Styles" />
                </motion.div>
              );
            })}
          </motion.div>
        </motion.div>
      </div>
      <div className="ServicesHome " id="services">
        <Service />
        <ServiceMobile />
      </div>
      <div className="section-break">
        <img src={sectionb} alt="breaks"></img>
      </div>

      <div ref={ref} className="OurCrew" id="ourcrew">
        <h1>OUR CREW</h1>

        <div>
          <img src={Helen} alt="Straight"></img>
          <h2>Helen Hailu</h2>
          <h3>Hair Stylist</h3>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/hhailu401/"
          >
            <img className="instagram" src={Instagram} alt="Curls"></img>
          </a>
        </div>
        <div>
          <img src={Mekdes} alt="Straight"></img>
          <h2>Mekdes</h2>
          <h3>Hair Stylist</h3>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/mekdesakwak/"
          >
            <img className="instagram" src={Instagram} alt="Curls"></img>
          </a>
        </div>
        <ul>
          <li className="BookingButton">
            <Link to="/contactus">CONTACT US</Link>
          </li>
        </ul>
      </div>
      <div className="Testimonials" id="testimonial">
        <h1>TESTIMONIALS</h1>
      </div>
      <div className="container">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: true,
          }}
          autoplay={{ delay: 5000 }}
          pagination={false}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
          loop={true}
        >
          <SwiperSlide>
            <div className="testimonialBox">
              <img src={quote} className="quote" alt=""></img>
              <div className="content">
                <p>
                  I had a great experience at this hair salon! The stylist was
                  very skilled and listened to exactly what I wanted. The salon
                  had a comfortable and welcoming atmosphere, and the service
                  was top-notch. I left with a fabulous new haircut and color
                  that I am getting lots of compliments on. I highly recommend
                  this salon to anyone in need of a new look!
                </p>
              </div>
              <div className="details">
                <div className="imgBx">
                  <img src={user1} alt=""></img>
                </div>
                <h3>
                  Gelila Abate
                  <br />
                </h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonialBox">
              <img src={quote} className="quote" alt=""></img>
              <div className="content">
                <p>
                  I highly recommend this hair salon! The stylist gave me the
                  perfect haircut and style that I was looking for. They took
                  the time to understand my needs and gave me a look that was
                  both stylish and easy to maintain. The salon was clean and
                  well-appointed, and the staff was friendly and professional. I
                  will definitely be returning for my next hair appointment!
                </p>
              </div>
              <div className="details">
                <div className="imgBx">
                  <img src={user2} alt=""></img>
                </div>
                <h3>
                  Rediet Debebe
                  <br />
                </h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonialBox">
              <img src={quote} className="quote" alt=""></img>
              <div className="content">
                <p>
                  The stylist was attentive and took the time to understand what
                  I was looking for in a haircut. They gave me a style that
                  suited my face shape and hair type. The salon was clean and
                  well-maintained, and the staff was friendly and professional.
                  I was impressed with the quality of service, and I would
                  recommend this salon to anyone looking for a new hair style.
                </p>
              </div>
              <div className="details">
                <div className="imgBx">
                  <img src={user3} alt=""></img>
                </div>
                <h3>
                  Hermela Michael
                  <br />
                </h3>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonialBox">
              <img src={quote} className="quote" alt=""></img>
              <div className="content">
                <p>
                  The best Ethiopian hair salon I have ever been to. Very
                  spacious and plenty of parking space. Most importantly very
                  friendly and good people. Their work is very satisfying. I
                  have never been disappointed. The place is full of love and
                  laughter. And if you are lucky you get to drink a very tasty
                  Ethiopian coffee.
                </p>
              </div>
              <div className="details">
                <div className="imgBx">
                  <img src={user4} alt=""></img>
                </div>
                <h3>
                Tsedenya Mengiste
                  <br />
                </h3>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Home;
