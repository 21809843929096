import image1 from '../assets/Hair1.jpg'
import image2 from '../assets/Hair2.jpg'
import image4 from '../assets/Hair4.jpg'
import image5 from '../assets/Hair5.jpg'
import image6 from '../assets/Hair6.jpg'
import image7 from '../assets/Hair7.jpg'
import image8 from '../assets/Hair8.jpg'
import image9 from '../assets/Hair9.png'
import image10 from '../assets/Hair10.jpg'
import image11 from '../assets/Hair11.jpg'
import image12 from '../assets/Hair12.jpg'
import image13 from '../assets/Hair13.jpg'
import image14 from '../assets/Hair14.jpg'
import image15 from '../assets/Hair15.jpg'
import image16 from '../assets/Hair16.jpg'
import image17 from '../assets/Hair17.jpg'
import image19 from '../assets/Hair19.jpg'
import image20 from '../assets/Hair20.jpg'

const imageArray = [image2, image1, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image19, image20];
export default imageArray;